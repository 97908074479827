import { ToastConsumer as KdsToastConsumer } from 'kds-react';
import React, { createContext, useContext } from 'react';

export const ToastContext = createContext();

// KDS does not expose the Toast Context, so we cannot use a hook to read on it's value.
// This wrapper copies the value to our own context so that we can consume it using hooks.
export const ToastConsumer = ({ children }) => (
  <KdsToastConsumer>
    {(value) => <ToastContext.Provider value={value}>{children}</ToastContext.Provider>}
  </KdsToastConsumer>
);

export const useToastContext = () => useContext(ToastContext);
