import { runtimeConfig } from '@kpr/utils/config';
import { loadableReady } from '@loadable/component';
import { hydrate } from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import App from './App';

loadableReady().then(() => {
  hydrate(
    <HelmetProvider>
      <BrowserRouter basename={runtimeConfig.basename}>
        <App />
      </BrowserRouter>
    </HelmetProvider>,
    document.getElementById('root'),
  );
});

if (module.hot) {
  module.hot.accept();
}
