import { getCart } from '@kpr/api/CustomersApi';
import { getModifyingOrder } from '@kpr/api/OrdersApi';
import get from 'lodash/get';
import { useCallback, useEffect } from 'react';

import { useUser } from '../User';
import { objectIdToGuid } from '../utils';
import { useCartDispatch } from './Cart';
import { actionTypes, initialState } from './reducer';

export function useRefresh() {
  const dispatch = useCartDispatch();
  const user = useUser();

  return useCallback(async () => {
    try {
      const DeliveryCenterId = get(user, 'subscriptionDetails.deliveryCenterId', false);

      if (!DeliveryCenterId) {
        throw new Error('User does not have a DeliveryCenterId');
      }

      if (get(user, 'subscriptionDetails.modifyingOrder', false)) {
        getModifyingOrder().then((order) => {
          dispatch({
            type: actionTypes.SET,
            payload: {
              ...order,
              guid: objectIdToGuid(order.orderId),
            },
          });
        });
      } else {
        const cart = await getCart(DeliveryCenterId);

        dispatch({
          type: actionTypes.SET,
          payload: cart || initialState,
        });
      }
    } catch (e) {
      dispatch({
        type: actionTypes.SET,
        payload: initialState,
      });
    }
  }, [dispatch, user]);
}

export function CartSync() {
  const refresh = useRefresh();

  useEffect(() => {
    refresh();
  }, [refresh]);

  return null;
}
