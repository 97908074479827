import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const DEFAULT_TIME_ZONE = 'America/New_York';

/**
 *
 * @param {date} - UTC date to convert
 * @param {timeZone} - desired time zone
 * @returns date relative to provided time zone
 */
const convertToTimeZone = (date, timeZone = DEFAULT_TIME_ZONE) => dayjs(date).tz(timeZone);

export default convertToTimeZone;
