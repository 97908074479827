import convertPriceToNumber from '@kpr/utils/convertPriceToNumber';
import convertToTimeZone from '@kpr/utils/date/convertToTimeZone';
import formatDate from '@kpr/utils/date/formatFullDate';
import formatTime from '@kpr/utils/date/formatTime';
import shapeCollection from '@kpr/utils/shapeCollection';
import queryString from 'query-string';

import { defaultProductKeyMap } from '../constants';
import request from '../request';
import { transformProduct } from '../utils';

export function createOrder(storeId, postData) {
  const url = `/v1/stores/${storeId}/customers/place_order`;

  return request(url, {
    method: 'POST',
    body: JSON.stringify({
      ...postData,
    }),
  });
}

export function getLastOrder(orderId) {
  const url = queryString.stringifyUrl({
    url: '/v1/order_details',
    query: {
      OrderId: orderId,
    },
  });

  const shapeCollectionOptions = {
    keyMap: defaultProductKeyMap,
    transforms: {
      [shapeCollection.__root]: (value) => {
        const order = value;

        return {
          ...order,
          DeliveryDate: formatDate(convertToTimeZone(order.DeliveryStartDateTime)),
          DeliveryEndTime: formatTime(convertToTimeZone(order.DeliveryDueDateTime)),
          DeliveryStartTime: formatTime(convertToTimeZone(order.DeliveryStartDateTime)),
        };
      },
      OrderItems: (value, next) =>
        next(
          value.map(({ OriginalProductDetails, ...item }) =>
            transformProduct({ ...item, ...OriginalProductDetails }, false, true),
          ),
        ),
    },
  };

  return request(url, { method: 'GET' }, shapeCollectionOptions);
}

export function getModifyingOrder() {
  const url = queryString.stringifyUrl({
    url: '/v2/orders',
    query: {
      Limit: 1,
      Page: 1,
    },
  });

  const shapeCollectionOptions = {
    transforms: {
      [shapeCollection.__root]: (value) => {
        const lastOrder = value.Orders[0];
        const oldItems = lastOrder.OrderItems;
        const modifiedOrder = lastOrder.ModifiedOrderDetails;
        return {
          IsOrderCart: true,
          IsValidTotal: convertPriceToNumber(modifiedOrder.OrderSubtotal) >= 20,
          OrderId: lastOrder._id,
          CartQuantity: modifiedOrder.OrderQuantity,
          CartSubtotal: modifiedOrder.OrderSubtotal,
          DeliveryFee: modifiedOrder.DeliveryFee,
          DeliveryDate: lastOrder.DeliveryStartDateTime,
          DeliveryEndTime: formatTime(lastOrder.DeliveryDueDateTime),
          DeliveryStartTime: formatTime(lastOrder.DeliveryStartDateTime),
          Discount: lastOrder.Discount,
          OrderDiscount: lastOrder.OrderDiscount,
          EstimatedTax: modifiedOrder.EstimatedTax,
          EstimatedTotal: modifiedOrder.EstimatedTotal,
          AppliedPromoCode: lastOrder.AppliedPromoCode,
          RemainingFourFreeRedemptions: lastOrder.FourFreeOrder ? 1 : 0,
          CartItems: modifiedOrder.OrderItems.map((item) => ({
            IsOldItem: oldItems.find((oldItem) => oldItem._id === item._id) !== null,
            Available: item.OriginalProductDetails.Available,
            CurrentPrice: item.OriginalProductDetails.OriginalPrice,
            SalePrice: item.OriginalProductDetails.OriginalSalePrice,
            ImageURL: item.OriginalProductDetails.ImageURL,
            ProductName: item.OriginalProductDetails.ProductName,
            Weight: item.OriginalProductDetails.Weight,
            Size: item.OriginalProductDetails.Size,
            WeightedItem: item.OriginalProductDetails.WeightedItem,
            CurrentPerUnitPrice: item.OriginalProductDetails.CurrentPerUnitPrice,
            SalePerUnitPrice: item.OriginalProductDetails.SalePerUnitPrice,
            Quantity: parseInt(item.Quantity, 10),
            ProductId: item.ProductId,
            Id: item._id,
            PersistentItem: item.PersistentItem,
            Note: item.Note,
            AllowSubstitution: item.AllowSubstitution,
            ProductRestrictions: item.OriginalProductDetails.ProductRestrictions,
          })),
        };
      },
    },
  };

  return request(url, { method: 'GET' }, shapeCollectionOptions);
}
