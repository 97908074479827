import convertPriceToNumber from '@kpr/utils/convertPriceToNumber';
import get from 'lodash/get';

export const transformProduct = (product, isCart = false, isOrder = false) => {
  // I hate to include the OR statement for non-weighted items,
  // but the API produces super inconsistent responses.
  let originalPrice = convertPriceToNumber(get(product, 'DFMCenterDetails[0].CurrentPrice'));
  let salePrice = convertPriceToNumber(product.WeightedItem ? product.SalePerUnitPrice : product.SalePrice);
  let isSoldByWeight = Boolean(product.WeightedItem);

  if (isCart) {
    isSoldByWeight = false;
    originalPrice = convertPriceToNumber(get(product, 'CurrentPrice'));
    salePrice = undefined;
  }

  if (isOrder) {
    isSoldByWeight = false;
    originalPrice = convertPriceToNumber(get(product, 'OriginalPrice'));
    salePrice = undefined;
  }

  return {
    ...product,
    totalPrice: {
      Original: isCart && convertPriceToNumber(get(product, 'TotalPrice')),
    },
    price: {
      Description: isSoldByWeight ? `${salePrice || originalPrice}/lb` : product.Size,
      IsSoldByWeight: isSoldByWeight,
      Original: originalPrice,
      Sale: salePrice,
    },
    productUrl: isCart ? `/p/${product.ProductId}` : `/p/${product._id}`,
    outOfStock: isCart ? get(product, 'OutOfStock', true) : get(product, 'DFMCenterDetails[0].OutOfStock', true),
    productRestrictions: {
      maxQuantity: isCart ? product.MaxQuantity : product.RemainingQuantity,
    },
    sellerUrl:
      typeof product.SellerCode === 'object'
        ? `/seller/${product.SellerCode._id}`
        : `/seller/${product.SellerCode || product.SellerId}`,
  };
};

export const transformSeller = (sellerData) => {
  return {
    ...sellerData,
    sellerUrl: `/seller/${get(sellerData, '_id')}`,
  };
};

export const transProductSubCategory = (subCategory) => {
  return {
    CategoryId: get(subCategory, 'SubCategoryId'),
    CategoryName: get(subCategory, 'SubCategoryName'),
    CategoryCode: get(subCategory, 'SubCategoryCode'),
  };
};
