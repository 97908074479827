export function pascalToCamel(key) {
  return key.replace(/^[A-Z]/, (c) => c.toLowerCase());
}

export function camelToPascal(key) {
  return key.replace(/^[a-z]/, (c) => c.toUpperCase());
}

const defaultIdentifier = Symbol('default');
const rootIdentifier = Symbol('root');

export default function shapeCollection(root, config = {}, isNext) {
  if (!root || typeof root !== 'object') {
    return root;
  }

  const { keyMap = {}, transforms = {} } = config;

  const next = (input) => shapeCollection(input, config, true);

  if (!isNext && transforms[rootIdentifier]) {
    const result = transforms[rootIdentifier](root, next);

    if (transforms[rootIdentifier].length === 1) {
      return next(result);
    }

    return result;
  }

  if (Array.isArray(root)) {
    return root.map(next);
  }

  return Object.entries(root).reduce((result, [key, value]) => {
    if (key in transforms) {
      value = transforms[key](value, next);
    } else if (value && typeof value === 'object') {
      value = next(value);
    }

    if (key in keyMap) {
      key = keyMap[key];
    } else {
      key = (keyMap[defaultIdentifier] || pascalToCamel)(key);
    }

    result[key] = value;

    return result;
  }, {});
}

shapeCollection.__default = defaultIdentifier;
shapeCollection.__root = rootIdentifier;
