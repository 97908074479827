import dayjs from 'dayjs';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';

dayjs.extend(LocalizedFormat);

/**
 *
 * @param {Date} date - date to parse
 * @returns {string} formatted date (e.g. August 1, 2018)
 */
const formatDate = (date) => dayjs(date).format('LL');

export default formatDate;
