export function objectIdToGuid(input) {
  return `${input.substring(0, 8)}-7765-656b-${input.substring(8, 12)}-${input.substring(12)}`;
}

export const getProductCategoryInfo = (function getProductCategoryInfo() {
  const cache = {};
  let requestedUPCs = [];
  let pendingPromise = null;

  function requestProductInfo(upc) {
    requestedUPCs.push(upc);

    if (!pendingPromise) {
      pendingPromise = new Promise((resolve) => {
        setTimeout(async () => {
          const upcs = requestedUPCs;
          requestedUPCs = [];
          pendingPromise = null;

          try {
            const res = await fetch('/products/api/products/details', {
              method: 'POST',
              credentials: 'include',
              body: JSON.stringify({
                upcs,
                filterBadProducts: false,
                clicklistProductsOnly: false,
                shouldFetchAisleLocation: false,
                shouldFetchNutritionInfo: false,
              }),
            });

            res.products.forEach(({ upc: productUpc, categories }) => {
              if (cache[productUpc]) return;

              cache[productUpc] = categories.reduce(
                (acc, { name, code }) => {
                  acc.categoryCode.push(code);
                  acc.categoryName.push(name);
                  return acc;
                },
                { categoryCode: [], categoryName: [] },
              );
            });
          } catch (e) {
            cache[upc] = {
              categoryCode: [],
              categoryName: [],
            };
          }

          resolve();
        }, 10);
      });
    }

    return pendingPromise;
  }

  return async function getInfo(upc) {
    if (!cache[upc]) {
      await requestProductInfo(upc);
    }

    return cache[upc];
  };
})();
