import { getBannerSubdomain, useConfig } from '@kpr/utils/config';
import shapeCollection from '@kpr/utils/shapeCollection';
import get from 'lodash/get';
import queryString from 'query-string';

import { defaultProductKeyMap } from '../constants';
import request from '../request';
import { transformProduct } from '../utils';

export const AUTH_STATUS_PENDING = 'pending';
export const AUTH_STATUS_SUCCESS = 'success';
export const AUTH_STATUS_FAILURE = 'failure';

export const useSignInUrl = () => {
  const config = useConfig();
  const redirectUri = `https://${getBannerSubdomain(process.env.RAZZLE_PCF_ENV)}.${
    config.bannerName
  }farmersmarket.com/oauth`;

  return queryString.stringifyUrl({
    url: `https://${
      process.env.RAZZLE_PCF_ENV === 'production' ? 'api' : 'api-ce'
    }.kroger.com/v1/connect/oauth2/authorize`,
    query: {
      client_id:
        process.env.RAZZLE_PCF_ENV === 'production'
          ? 'digitalfarmersmarket-8fcc682a5acb5e816e9f7e44f6cd956a2271910268787443922'
          : 'digitalfarmersmarket-test-09f677e0d9ad31f3bbc77fe60ed4bdc5699751519746924704',
      response_type: 'code',
      scope: 'profile.full',
      redirect_uri: redirectUri,
    },
  });
};

export const useSignUpUrl = () => {
  const config = useConfig();
  const signInUrl = useSignInUrl();

  return queryString.stringifyUrl({
    url: `https://www.${config.bannerName}.com/account/create/`,
    query: {
      redirectUrl: signInUrl,
    },
  });
};

export function getCart(storeId) {
  const url = `/v1/${storeId}/customers/cart`;

  const shapeCollectionOptions = {
    keyMap: defaultProductKeyMap,
    transforms: {
      [shapeCollection.__root]: (value) => {
        return {
          ...value,
          IsValidTotal: true,
        };
      },
      CartItems: (value, next) => next(value.map((product) => transformProduct(product, true))),
    },
  };

  return request(url, { method: 'GET', credentials: 'same-origin' }, shapeCollectionOptions);
}

export function getUser() {
  const url = '/v1/customers/profile';

  const shapeCollectionOptions = {
    transforms: {
      [shapeCollection.__root]: (value) => {
        return {
          ...value,
          AuthenticationStatus: AUTH_STATUS_SUCCESS,
          IsAuthenticated: true,
          HasAddress: Boolean(get(value, 'DeliveryAddress.AddressLine1')),
        };
      },
    },
  };

  return request(url, { method: 'GET', credentials: 'same-origin' }, shapeCollectionOptions)
    .then((data) => data || { authenticationStatus: AUTH_STATUS_FAILURE, isAuthenticated: false })
    .catch(() => ({ authenticationStatus: AUTH_STATUS_FAILURE, isAuthenticated: false }));
}

export function checkAvailability(data) {
  const url = `/v1/customer/check_availability`;

  return request(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function notifyMe(data) {
  const url = `/v1/customers/notify_me`;

  return request(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function signIn(data) {
  const url = `/v2/customers/sign_in`;

  return request(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function signOut() {
  const url = `/v1/customers/sign_out`;

  return request(url, {
    method: 'POST',
    credentials: 'same-origin',
  });
}

export function uspsAddressValidate(data) {
  const url = '/v1/customers/usps_address_validate';

  return request(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function createProfile(data) {
  const url = '/v1/customers/create_profile';

  return request(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}

export function addressValidate(data) {
  const url = '/v1/validate_address';

  return request(url, {
    method: 'POST',
    body: JSON.stringify(data),
  });
}
