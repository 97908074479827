import auth from '@kpr/store/auth';
import { runtimeConfig } from '@kpr/utils/config';
import shapeCollection from '@kpr/utils/shapeCollection';
import urljoin from 'url-join';

import { defaultKeyMap } from '../constants';

const API_BASE_URL = '/api';

const defaultShapeCollectionOptions = {
  keyMap: defaultKeyMap,
};

export default function request(url, { headers, ...fetchOptions }, shapeCollectionOptions = {}) {
  const requestUrl = urljoin(runtimeConfig.basename || '/', API_BASE_URL, url);

  return fetch(requestUrl, {
    headers: {
      Authorization: auth.accessToken ? `Bearer ${auth.accessToken}` : undefined,
      ...headers,
    },
    ...fetchOptions,
  })
    .then(async (response) => {
      const data = await response.json();
      if (!response.ok) return Promise.reject(data);
      return data;
    })
    .then((data) => {
      if (data.Status === 'failure') {
        return Promise.reject(data.Error.Message);
      }

      return shapeCollection(data.Data, { ...defaultShapeCollectionOptions, ...shapeCollectionOptions });
    })
    .catch((data) => {
      if (typeof data === 'string') {
        return Promise.reject(data);
      }
      if (data.Status === 'failure') {
        return Promise.reject(data.Error.Message);
      }
    });
}
