import { CartProvider, CartSync } from '@kpr/store/Cart';
import { UserProvider } from '@kpr/store/User';
import { MediaContextProvider } from '@kpr/utils/media';
import { ToastConsumer } from '@kpr/utils/ToastContext';
import loadable from '@loadable/component';
import { ToastProvider, LoadingSpinner } from 'kds-react';
import { Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import 'kds-react/kds-reset.css';
import 'kds-react/kds-styles.css';
import 'kds-react/kds-utils.css';
import './App.css';

const fallback = <LoadingSpinner />;

const Home = loadable(() => import('@kpr/pages/Home').then((res) => res.default), { fallback });

export default function App() {
  return (
    <MediaContextProvider>
      <ToastProvider>
        <ToastConsumer>
          <UserProvider>
            <CartProvider>
              <CartSync />
              <LastLocationProvider>
                <Switch>
                  <Route component={Home} />
                </Switch>
              </LastLocationProvider>
            </CartProvider>
          </UserProvider>
        </ToastConsumer>
      </ToastProvider>
    </MediaContextProvider>
  );
}
