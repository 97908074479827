import { AUTH_STATUS_PENDING } from '@kpr/api/CustomersApi';
import { getUser } from '@kpr/api/CustomersApi';
import get from 'lodash/get';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import useBeforeUnload from 'react-use/lib/useBeforeUnload';

const initialState = {
  authenticationStatus: AUTH_STATUS_PENDING,
  isAuthenticated: false,
};

export const UserContext = createContext(initialState);
export const UserUpdaterContext = createContext(() => {});

export function UserProvider({ children }) {
  const [user, setUser] = useState(initialState);

  const updateUser = useCallback(async (cb) => {
    const newUser = await getUser();
    setUser(newUser);
    if (cb) cb(newUser);
  }, []);

  useEffect(() => {
    updateUser();
  }, [updateUser]);

  useBeforeUnload(get(user, 'subscriptionDetails.modifyingOrder', false), 'Are you sure you want to leave?');

  return (
    <UserContext.Provider value={user}>
      <UserUpdaterContext.Provider value={updateUser}>{children}</UserUpdaterContext.Provider>
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}

export function useUserUpdater() {
  return useContext(UserUpdaterContext);
}
