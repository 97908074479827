export const initialState = {};

export const actionTypes = {
  SET: 'SET',
};

export function reducer(state, action) {
  switch (action.type) {
    case actionTypes.SET:
      return action.payload;
    default:
      return state;
  }
}
