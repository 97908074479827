export const defaultKeyMap = {
  _id: 'id',
};

export const defaultProductKeyMap = {
  ...defaultKeyMap,
  ImageURL: 'imageUrl',
  SKU: 'upc',
};

export const PAGE_SIZE = 12;
