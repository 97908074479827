import dayjs from 'dayjs';

/**
 *
 * @param {Date} date - date to parse
 * @returns {string} formatted time (e.g. 3:30 PM)
 */
const formatTime = (date) => dayjs(date).format('h:mm A');

export default formatTime;
