import { createContext, useContext } from 'react';

export const getBannerSubdomain = (environment) => {
  if (!environment) return 'www-test';

  switch (environment) {
    case 'production':
      return 'www';
    case 'development':
      return 'www-test';
    default:
      return `www-${environment}`;
  }
};

export const getDeliveryCenterId = () => {
  if (['production', 'stage'].includes(process.env.RAZZLE_PCF_ENV)) return '609e8edb29a0e50086a0f106';
  return '6033be50daf1f20086b390ed';
};

export const getPayPageId = () => {
  if (process.env.RAZZLE_PCF_ENV === 'production') return 'DAJ3GaRLSnUXf4zb';
  return 'yMgDUeFaNY5j3sfh';
};

export const getEprotectUrl = () => {
  if (process.env.RAZZLE_PCF_ENV === 'production') return 'https://request.eprotect.vantivcnp.com';
  return 'https://request.eprotect.vantivprelive.com';
};

export const getBanner = (origin = 'kroger') =>
  ['kroger', 'fredmeyer', 'qfc'].find((banner) => origin.includes(banner)) || 'kroger';

export const getNextdoorId = (bannerName) => {
  if (bannerName === 'qfc') return process.env.RAZZLE_QFC_NEXTDOOR_ID;
  if (bannerName === 'fredmeyer') return process.env.RAZZLE_FREDMEYER_NEXTDOOR_ID;
  return process.env.RAZZLE_KROGER_NEXTDOOR_ID;
};

// Ignoring coverage wasn't working when this was inlined, hence the extrapolation.
/* istanbul ignore next */
const ssrConfig = {
  applicationName: process.env.RAZZLE_APPLICATION_NAME || 'Farmers Market',
  basename: process.env.RAZZLE_BASENAME || '',
  coreApiUrl:
    process.env.RAZZLE_IS_BANNER_APP === 'true'
      ? process.env.CORE_API_BASE_URL
      : `${process.env.RAZZLE_BASENAME || ''}/core-api`,
};

export const runtimeConfig =
  typeof window !== 'undefined'
    ? window.env
    : // Server-side config values
      // Disabling coverage because this code does not run in browser
      /* istanbul ignore next */
      ssrConfig;

export const ConfigContext = createContext(runtimeConfig);

export const useConfig = () => useContext(ConfigContext);
