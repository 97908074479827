import { createContext, useContext, useReducer } from 'react';

import { reducer, initialState } from './reducer';

export const CartContext = createContext(initialState);
export const CartDispatchProvider = createContext(() => {});

export function CartProvider({ children }) {
  const [cart, dispatch] = useReducer(reducer, initialState);

  return (
    <CartContext.Provider value={cart}>
      <CartDispatchProvider.Provider value={dispatch}>{children}</CartDispatchProvider.Provider>
    </CartContext.Provider>
  );
}

export function useCart() {
  return useContext(CartContext);
}

export function useCartCount() {
  const cart = useCart();

  return cart.cartQuantity || 0;
}

export function useCartDispatch() {
  return useContext(CartDispatchProvider);
}
