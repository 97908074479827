import Cookies from 'js-cookie';

export default {
  get accessToken() {
    return Cookies.get('accesstoken', { domain: window.location.hostname });
  },
  set accessToken(value) {
    Cookies.set('accesstoken', value, { domain: window.location.hostname, sameSite: 'strict' });
  },
};
