import { createMedia } from '@artsy/fresnel';

export const breakpoints = {
  sm: '37.25em',
  md: '56.25em',
  lg: '75em',
};

const up = (breakpoint) => `@media (min-width: calc(${breakpoint} + 0.0625em))`;
const down = (breakpoint) => `@media (max-width: ${breakpoint})`;

export const media = {
  smUp: up(breakpoints.sm),
  smDown: down(breakpoints.sm),
  mdUp: up(breakpoints.md),
  mdDown: down(breakpoints.md),
  lgUp: up(breakpoints.lg),
  lgDown: down(breakpoints.lg),
};

// used for @artsy/fresnel's conditional rendering
const AppMedia = createMedia({
  breakpoints: {
    sm: 0,
    md: 596, // px equivalent of breakpoints.sm
    lg: 900, // px equivalent of breakpoints.md
    xl: 1200, // px equivalent of breakpoints.lg
  },
});

export const mediaStyles = AppMedia.createMediaStyle();

export const { Media, MediaContextProvider } = AppMedia;
